













import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';

@Component
export default class PlaceholderCard extends Vue {
  @Prop({ default: 'primary' }) readonly barColor!: string;
  @Prop({ default: 'article, actions' }) readonly skeletonType!: string;
  @Prop({ default: false }) readonly popup!: boolean;

  private theme = proxies.Theme;
};
