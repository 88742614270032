









import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs, { Dayjs } from '@/common/dayjs';

@Component
export default class RelativeTimeTooltip extends Vue {
  @Prop([Date, String, Number, Dayjs]) readonly datetime!: Date | string | number | Dayjs;

  private get readableDate(): string {
    return dayjs(this.datetime).fromNow();
  }

  private get formattedDate(): string {
    return dayjs(this.datetime).format('lll');
  }
}
