































import { gql } from 'apollo-boost';
import { queries, schema } from '@/gql';
import { Vue, Component, Prop } from 'vue-property-decorator';
import UserAvatarTooltip from '@/components/output/UserAvatarTooltip.vue';
import RelativeTimeTooltip from '@/components/tooltip/RelativeTimeTooltip.vue';
import SectionContainer from "@/components/section/SectionContainer.vue";
import PlaceholderCard from "@/components/section/PlaceholderCard.vue";
import HeadingCard from "@/components/section/HeadingCard.vue";
import SearchInput from '@/components/input/SearchInput.vue';
import KnowledgeBreadcrumbs from '@/components/output/KnowledgeBreadcrumbs.vue';

import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import sql from 'highlight.js/lib/languages/sql';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import csharp from 'highlight.js/lib/languages/csharp';
import java from 'highlight.js/lib/languages/java';
import c from 'highlight.js/lib/languages/c';
import cpp from 'highlight.js/lib/languages/cpp';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import gml from 'highlight.js/lib/languages/gml';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('php', php);
hljs.registerLanguage('python', python);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('java', java);
hljs.registerLanguage('c', c);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('css', css);
hljs.registerLanguage('gml', gml);

const md = new MarkdownIt({
  typographer: true,
  linkify: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      return hljs.highlight(str, {language: lang}).value
    }
    return ''
  }
})


@Component({
  components: {
    SectionContainer,
    PlaceholderCard,
    HeadingCard,
    SearchInput,
    UserAvatarTooltip,
    RelativeTimeTooltip,
    KnowledgeBreadcrumbs,
  },
  apollo: {
    knowledgebaseUid: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid};
      },
      update(query: schema.Query): string {
        return query.org.knowledgebase.uid;
      },
    },
    article: {
      fetchPolicy: 'cache-and-network',
      query: gql`
        query knowledgebaseArticle($channelUid: String!, $eid: String!) {
          arkMessageByEid(channelUid: $channelUid, eid: $eid) {
            id
            authorUid
            timestamp
            joinedMessage {
              text
            }
            answers {
              eid
              contributors {
                id
                uid
              }
              joinedMessage {
                text
              }
            }
          }
        }
      `,
      variables(): schema.QueryArkMessageByEidArgs {
        return {
          channelUid: this.knowledgebaseUid,
          eid: this.messageEid
        };
      },
      error(err) {
        this.error = err.graphQLErrors[0].message;
        console.error(err);
      },
      skip() {
        return !this.messageEid || !this.knowledgebaseUid;
      },
      update(query: schema.Query) {
        this.error = '';
        return query.arkMessageByEid;
      },
    },
  },
})
export default class Article extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private knowledgebaseUid!: string;

  private article!: schema.ArkMessage;
  private error: string = '';

  private get messageEid() {
    return this.$route.params.messageEid;
  }

  private get renderedDocument() {
    return md.render(this.title+this.content);
  }

  private get title() {
    if (!this.article) return '# (untitled)';
    return `# ${this.article.joinedMessage.text.join('\n\n')}\n\n`;
  }

  private get content() {
    if (!this.article) return '(no content)';
    return this.article?.answers.map(answer => answer.joinedMessage.text.join('\n\n')).join('\n\n___\n\n')
  }

  private get loading(): boolean {
    return !this.article || this.$apollo.queries.knowledgebaseUid.loading || this.$apollo.queries.article.loading;
  }

  // private get breadcrumbItems() {
  //   return [
  //     {
  //       text: 'Arkive.ai',
  //       exact: true,
  //       to: {
  //         name: 'home',
  //       },
  //     },
  //     {
  //       text: 'Knowledgebase',
  //       exact: true,
  //       to: {
  //         name: 'knowledgebase',
  //       },
  //     },
  //   ];
  // }
}
