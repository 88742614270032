














import { Vue, Component, Prop } from 'vue-property-decorator';
import { mdiMagnify } from '@mdi/js'
import { proxies } from '@/store';


@Component({
})
export default class SearchInput extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private mdiMagnify = mdiMagnify;
  private query: string = '';

  private get cleanQuery(): string {
    return this.query?.replace(/[^\w\s]/gi, '').trim() || '';
  }

  private doSearch(): void {
    this.$router.push({
      name: 'explore',
      params: {orgUidOrDomain: this.orgUid},
      query: {search: this.cleanQuery}
    });
  }
}
