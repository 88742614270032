


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { queries, schema } from '@/gql';
import { proxies } from '@/store';
import { mdiAccount } from '@mdi/js'

@Component({
  apollo: {
    userSource: {
      query: queries.userSourceByUid,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.authorUid;
      },
      variables(): schema.QueryUserSourceArgs {
        return {
          uid: this.authorUid,
        };
      },
      update(query: schema.Query) {
        return query.userSource;
      },
      error() {
        return;
      }
    },
  }
})
export default class UserAvatarTooltip extends Vue {
  @Prop(String) private readonly authorUid!: string;
  @Prop({ default: 48, type: [String, Number]}) private readonly size!: string | number;
  
  private theme = proxies.Theme;
  private userSource!: schema.UserSource;
  private mdiAccount = mdiAccount;

  private get iconsize() {
    return parseInt(this.size.toString())*2/3;
  }
  private get hasUrl() {
    return !!this.imageUrl;
  }
  private get imageUrl() {
    return this.userSource?.profilePictureUrl;
  }
  private get tooltip() {
    return this.userSource?.displayName || 'Anonymous User';
  }
  private get loading() {
    return !this.userSource && this.$apollo.queries.userSource.loading;
  }
}
